import { DataWrapped, Influencer } from '@cardo/types';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export * from './brandedPage';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getInfluencerName(
  influencer: Influencer | DataWrapped<Influencer> | DataWrapped<null>
) {
  if ('data' in influencer && influencer.data === null) {
    return '';
  }

  const attributes =
    'data' in influencer ? influencer.data.attributes : influencer.attributes;
  return attributes.displayName || attributes.username;
}
